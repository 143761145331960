import { IconProps } from '@mui/material';
import React, { CSSProperties, FunctionComponent } from 'react';
import { MuiIconManifest } from 'utils/iconManifest';

interface VerifiedBadgeProps {
  fontSize: 'medium' | 'large';
  paddingBottom?: CSSProperties['padding'] | null;
  sx?: IconProps['sx'];
}

export const VerifiedBadge: FunctionComponent<VerifiedBadgeProps> = ({
  fontSize,
  paddingBottom,
  sx,
}) => (
  <MuiIconManifest.CheckCircleIcon
    style={{
      color: '#18A2F2',
      paddingBottom:
        paddingBottom === undefined
          ? '0.2em' // todo see if I can get rid of this hack
          : paddingBottom === null
          ? undefined
          : paddingBottom,
    }}
    sx={sx}
    fontSize={fontSize}
  />
);
