import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Container,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import LogoText from 'assets/img/theme/front/logo-text.png';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import { AutocompleteStandalone } from '../Explore/AutocompleteStandalone';
import { UserIconWithFallback } from './UserIconWithFallback';
import { useUserState } from 'hooks/common/useUserState';
import { MuiIconManifest } from 'utils/iconManifest';

const style = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  navBar: {
    zIndex: 100,
    '&.front': {
      background: 'transparent',
      boxShadow: 'none',
    },
    background: 'linear-gradient(97deg, #543fed 22%, #462ac8 102%)',
  },
  container: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  navLogo: {
    height: '50px',
    width: '50px',
    display: 'inline-block !important;',
    '&:before': {
      display: 'inline-block',
      fontFamily: 'VDXLogo',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '50px',
      color: theme.palette.common.white,
      lineHeight: 1,
      webkitFontSmoothing: 'antialiased',
      mozOsxFontSmoothing: 'grayscale',
      content: '"A"',
    },
  },
  logoText: {
    height: '25px',
    verticalAlign: 'initial',
    marginLeft: 16,
  },
  leftIcon: {
    padding: 0,
    marginRight: theme.spacing(2),
    borderRadius: 0,
    '&:hover': {
      background: 'none',
    },
  },
  search: {
    position: 'relative',
    borderRadius: '50vh',
    '&.suggestions': {
      borderTopRightRadius: '20px',
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.95),
    },
    //marginRight: theme.spacing(2),
    marginLeft: 0,
    color: theme.palette.common.black,
  },
  searchIcon: {
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchInput: {
    boxSizing: 'border-box',
    '& input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '40ch',
      },
      color: 'inherit',
      background: 'none',
      //boxSizing: 'content-box',
      boxShadow: 'none',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuItem: {
    minHeight: 0,
  },
  typography: {
    fontFamily: "'Barlow', Helvetica, sans-serif",
    fontWeight: 500,
    height: '40px',
    lineHeight: '40px',
    '&.nli': {
      width: '100%',
      textAlign: 'right',
    },
  },
  lock: {
    fontSize: 'inherit',
    marginBottom: 3,
    marginRight: 4,
    verticalAlign: 'middle',
  },
  links: {
    color: '#d7e1fe',
    '&.selected': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
    marginLeft: 16,
    marginRight: 16,
  },
  notLoggedInButtons: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    minWidth: '170px',
  },
}));

const frontButtonTheme = makeStyles((theme) => ({
  signUpButton: {
    color: theme.palette.common.white,
    backgroundColor: '#39ba3a',
    padding: '17px 39px',
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: '#39ba3a',
    },
    border: 'none',
    verticalAlign: 'baseline',
    borderRadius: '8px',
  },
}));

const MaterialNavbar = ({ showSearchBar = true, front = false }) => {
  const classes = style();
  const { isFreeUser } = useUserState();
  const frontButtonClasses = frontButtonTheme();

  const [searchString] = useState('');
  const [stringLength] = useState(0);
  const [focus] = useState(false);
  const [searchRedirect, setSearchRedirect] = useState(false);
  const [suggestions] = useState([]);
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMenuAnchor);
  const { pathname } = useLocation();
  const navClasses = front ? classes.navBar + ' front' : classes.navBar;

  const mobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const mobileMenuOpen = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const menuUrls = {
    search: '/app/events/search',
    home: '/app/home',
    lists: '/app/myprospects/lists',
    planner: '/app/planner',
  };

  const linkClasses = (href) => {
    if (href === pathname) return classes.links + ' selected';
    return classes.links;
  };

  const searchClasses = () => {
    if (suggestions.length > 0 && stringLength > 0 && focus)
      return classes.search + ' suggestions';
    return classes.search;
  };

  const mobileMenuId = 'navBarMobileMenu';
  const mobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchor}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={mobileMenuClose}
      onClick={mobileMenuClose}
      MenuListProps={{
        disablePadding: true,
      }}
    >
      <MenuItem className={classes.menuItem} component={Link} to="/app/home">
        <ListItemIcon>
          <MuiIconManifest.HomeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Home</ListItemText>
      </MenuItem>
      <MenuItem
        className={classes.menuItem}
        component={Link}
        to="/app/events/search"
      >
        <ListItemIcon>
          <MuiIconManifest.SearchIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Explore</ListItemText>
      </MenuItem>
      {!isFreeUser && (
        <MenuItem
          className={classes.menuItem}
          component={Link}
          to={menuUrls.lists}
        >
          <ListItemIcon>
            <MuiIconManifest.ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Lists</ListItemText>
        </MenuItem>
      )}
      <MenuItem
        className={classes.menuItem}
        component={Link}
        to={menuUrls.planner}
      >
        <ListItemIcon>
          <MuiIconManifest.EventNoteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Planner</ListItemText>
      </MenuItem>
    </Menu>
  );

  if (searchRedirect) {
    setSearchRedirect(false);
    window.location.href = searchString;
    return <React.Fragment></React.Fragment>;
  }

  const signupButtonProps = {
    defaultStyling: !front,
    label: front ? 'Start for free' : '',
    theme: front ? frontButtonClasses : '',
  };

  return (
    <>
      <AppBar position="static" id="adminNavBar" className={navClasses}>
        <Container className={classes.container}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={mobileMenuOpen}
                color="inherit"
                className={classes.leftIcon}
                size="large"
              >
                <MuiIconManifest.MenuIcon />
              </IconButton>
            </div>
            <div className={'navbar-vdx-logo-container'}>
              <IconButton
                className={classes.leftIcon}
                href="/app/events/search"
                aria-label="home"
                size="large"
              >
                <span className={classes.navLogo} />
                {front && (
                  <img
                    className={classes.logoText}
                    width="140"
                    height="25"
                    src={LogoText}
                    alt="Vendelux logotype"
                  />
                )}
              </IconButton>
            </div>
            <div className={classes.sectionDesktop}>
              {showSearchBar && (
                <div className={searchClasses()} id="adminNavBarSearchGroup">
                  <div className={classes.searchIcon}>
                    <MuiIconManifest.SearchIcon />
                  </div>
                  <AutocompleteStandalone
                    scrollFix={() => {
                      document?.querySelector('html')?.scrollTo(0, 0);
                    }}
                    rounded={true}
                  />
                </div>
              )}
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionMobile}>
              {showSearchBar && (
                <div className={searchClasses()} id="adminNavBarSearchGroup">
                  <div className={classes.searchIcon}>
                    <MuiIconManifest.SearchIcon />
                  </div>
                  <AutocompleteStandalone
                    scrollFix={() => {
                      document?.querySelector('html')?.scrollTo(0, 0);
                    }}
                    rounded={true}
                  />
                </div>
              )}
            </div>
            {!front && (
              <div className={classes.sectionDesktop}>
                <Typography className={classes.typography}>
                  <Link
                    className={linkClasses(menuUrls.home)}
                    to={menuUrls.home}
                  >
                    Home
                  </Link>
                  <Link
                    className={linkClasses(menuUrls.search)}
                    to={menuUrls.search}
                  >
                    Explore
                  </Link>
                  {!isFreeUser && (
                    <Link
                      className={linkClasses(menuUrls.lists)}
                      to={menuUrls.lists}
                    >
                      Lists
                    </Link>
                  )}
                  <Link
                    className={linkClasses(menuUrls.planner)}
                    to={menuUrls.planner}
                  >
                    Planner
                  </Link>
                </Typography>
              </div>
            )}
            <div className={classes.grow} />

            <UserIconWithFallback
              render={({ children }) => <div>{children}</div>}
            >
              <div className={classes.notLoggedInButtons}>
                <Typography className={classes.typography + ' nli'}>
                  <LoginButton className={linkClasses(menuUrls.planner)} />
                  <SignupButton {...signupButtonProps} />
                </Typography>
              </div>
            </UserIconWithFallback>
          </Toolbar>
        </Container>
      </AppBar>
      {mobileMenu}
    </>
  );
};

export default MaterialNavbar;
