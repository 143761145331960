import { Alert, Link, Typography, useTheme } from '@mui/material';
import { MuiIconManifest } from 'utils/iconManifest';

export function LimitedResultsAlertUI() {
  const theme = useTheme();

  return (
    <Alert
      severity="warning"
      iconMapping={{
        warning: <MuiIconManifest.InfoOutlinedIcon fontSize="inherit" />,
      }}
      sx={{
        backgroundColor: theme.vdxColors.vdxYellow[250],
      }}
    >
      <Typography>We've limited your list to 50,000 results.</Typography>
      <Typography>
        We recommend revisiting the list criteria to narrow to a more specific
        and actionable list of profiles or organizations.
      </Typography>
      <Typography>
        If you have questions, or need support, please email your Vendelux
        Representative or{' '}
        <Link href="mailto:support@vendelux.com">support@vendelux.com</Link>.
      </Typography>
    </Alert>
  );
}

/**
 * Show cautionary text to users if the API has limited the results.
 */
export function LimitedResultsAlert({ list }: { list: any }) {
  if (list.length >= 50000) {
    return <LimitedResultsAlertUI />;
  }

  return null;
}
